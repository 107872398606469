import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import {get,first} from 'lodash'
import Layout from '../components/layout'
import SocialLinks from '../components/socialLinks'
import Paragraphs from '../components/helpers/paragraphs';
import { getDataForLanguage } from '../utils/getDataForLanguage'
import Select from 'react-select'
import Recaptcha from 'react-google-recaptcha'

export class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          buttonDisabled: true
        };
      }

    render() {
        const allPageData = get(this, "props.data.allContentfulContactPage.edges", '');
        let pageData = first(getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY));
        const recaptchaRef = React.createRef();

        const options = [{label: "Hand", value: "hand"},
                         {label: "Foot", value: "foot"},
                         {label: "Lip", value: "lip"},
                         {label: "Body", value: "body"}];

        return (
            <Layout
                title={get(pageData, 'metaTitle','')} 
                description={get(pageData, 'metaDescription','')}
                pathName="/contact"
            >
                <div className="contact-page contact-page--top">
                    <div className="container" id="maincontent">
                        <h1 className="page-title text-center">{get(pageData,'title','')}</h1>
                        <div className="row relative-row">
                            <div className="w-50 contact-box contact-box--white">
                                <h2 className="contact-box--white__title">{get(pageData,'descriptionTitle','')}</h2>
                                <div className="contact-box--white__description">
                                    <Paragraphs value={get(pageData,'description','')} />
                                </div>
                            </div>
                            <div className="w-50 contact-box contact-box--green">
                                <h3 className="contact-box--green__title">
                                    {get(pageData,'contactUsText','')}
                                </h3>

                                <div className="contact-box--green__item">
                                    <div className="contact-box--green__label">
                                        Phone
                                    </div>
                                    <div className="contact-box--green__value">
                                        <a href={"tel:" + get(pageData,'phone','')}>{get(pageData,'phone','')}</a>
                                    </div>
                                </div>

                                <div className="contact-box--green__item">
                                    <div className="contact-box--green__label">
                                        Open
                                    </div>
                                    <div className="contact-box--green__value">
                                        {get(pageData,'openingHours', false) && pageData.openingHours.map((value) => {
                                            return (<p key={value}>{value}</p>)
                                        })}
                                    </div>
                                </div>

                                <div className="contact-box--green__link text-center">
                                    <Link className="btn btn--primary btn--green" to="/contact/#contact-form">{get(pageData, 'emailUsGreenBoxButtonText', 'Email Us')}</Link>
                                </div>
                            </div>
                            <div id="contact-form"></div>
                        </div>
                        <div className="row">
                            <div className="w-100 contact-box contact-box--form">
                                <form className="contact-form--form" name="Contact Page Form" method="POST" data-netlify="true" action="/success">
                                {/* <form className="contact-form--form" name="ContactPageForm" method="POST" data-netlify="true" action="/success" data-netlify-recaptcha="true"> */}
                                    <input type="hidden" name="form-name" value="Contact Page Form" />

                                    <div className="field field-container">
                                        <div className="field field--half">
                                            <label>First Name *
                                                <input type="text" name="first_name" required />
                                            </label>
                                        </div>

                                        <div className="field field--half">
                                            <label>Last Name *
                                                <input type="text" name="last_name" required /> 
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div className="field field-container">
                                        <div className="field field--half">
                                            <label>Email Address *
                                                <input type="email" name="email_address" required />
                                            </label>
                                        </div>

                                        <div className="field field--half">
                                            <label>Phone Number *
                                                <input type="text" name="phone_number" required />
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div className="field">
                                        <label>Address
                                            <input type="text" name="address" />
                                        </label>
                                    </div>

                                    <div className="field">
                                        <label>Apt, Suite, Company, C/O
                                            <input type="text" name="address_apt_suite_company_co" />
                                        </label>
                                    </div>

                                    <div className="field field-container">
                                        <div className="field field--half">
                                            <label>City
                                                <input type="text" name="city" />
                                            </label>
                                        </div>

                                        <div className="field field--half">
                                            <label>State / Province / Region
                                                <input type="text" name="state" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="field field-container">
                                        <div className="field field--half">
                                            <label>Zip / Postal Code
                                                <input type="text" name="postal_code_zip_code" />
                                            </label>
                                        </div>

                                        <div className="field field--half">
                                            <label>Country
                                                <input type="text" name="country" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="field field-container">
                                        <div className="field field--half field--select">
                                            <label>Product name *
                                                <Select placeholder="" name="product_name" options={options} />
                                            </label>
                                        </div>

                                        <div className="field field--half">
                                            <label>Product UPC Code
                                                <input type="text" name="product_upc_code" />
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div className="field">
                                        <label>Comment *
                                            <textarea name="comment" required />
                                        </label>
                                    </div>
                                    
                                    {/*<div className="field field-container" style={{ width: 'fit-content', margin: '0 auto 30px auto' }}>
                                        <div data-netlify-recaptcha="true"></div>
                                    </div>*/}

                                    <div className="field field-container" style={{ width: 'fit-content', margin: '0 auto 30px auto' }}>
                                        <Recaptcha
                                            ref={recaptchaRef}
                                            sitekey="6LfbogklAAAAAJj-TDY-SFHfYgfnJc_vjh6a1nwK"
                                            size="normal"
                                            id="recaptcha-google"
                                            onChange={() => this.setState({ buttonDisabled: false })}
                                        />
                                    </div>

                                    <div className="text-center"><button type="submit" className="btn btn--primary btn--submit" disabled={this.state.buttonDisabled}>{get(pageData, 'formSendButtonText', 'Submit')}</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-page contact-page--bottom">
                    <div className="container contact-box contact-box--social">
                        <h2 className="contact-box--social__title">{get(pageData,'socialTitle','')}</h2>
                        <div className="contact-box--social__description">{get(pageData,'socialDescription','')}</div>
                        <SocialLinks />
                    </div>
                </div>
            </Layout>
        )
    }
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageQuery {
    allContentfulContactPage {
        edges {
          node {
            metaTitle
            metaDescription
            title
            phone
            emailAddress
            openingHours
            contactUsText
            socialTitle
            socialDescription
            descriptionTitle
            emailUsGreenBoxButtonText
            formSendButtonText
            description {
              description
            }
            node_locale
            contentful_id
          }
        } 
      }
  }
`
